<template>
	<div class="CreatedMeterial" v-loading="loading" style="padding-bottom: 50px;">
		<el-form
			:model="ruleForm"
			:rules="rules"
			ref="ruleForm"
			label-width="130px"
			class="demo-ruleForm"
		>
			<div class="baseInformtion">
				<div class="infortion">素材内容</div>
				<el-form-item label="素材类型" prop="MaterialType">
					<el-select :disabled="type==1" v-model="ruleForm.MaterialType" placeholder="请选择商品分组" @change="ChangeMeterial">
						<el-option
							v-for="item in MeterialTypeList"
							:key="item.Id"
							:label="item.Name"
							:value="item.Id" 
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="选择商品" prop="selectProduct">
					<el-button type="primary" :disabled="type==1" style="width:100px;" @click="handleSelectProDialog">选择商品</el-button>
					<el-table border v-if="ruleForm.selectProduct.length" :data="ruleForm.selectProduct" style="width: 100%;margin: 20px 0;">
						<el-table-column label="商品" width="450px">
							<template slot-scope="scope">
								<div style="display: flex;align-items: center;">
									<img style="width: 60px;height: 60px;" :src="scope.row.thumbImgUrl" />
									<div class="right" style="margin-left: 10px;">
										<div class="name">
											<pre style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{scope.row.goodsName}}</pre>
										</div>
										<!-- <div style="margin-top: 5px;">{{scope.row.goodsCode}}</div> -->
									</div>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="salePrice" label="售价"></el-table-column>
						<el-table-column prop="totalStock" label="库存"></el-table-column>
						<el-table-column label="操作" v-if="type!=1">
							<template slot-scope="scope">
								<el-button style="color:#F56C6C;" @click="deleteSortPro(scope.row.id)" type="text" size="small">删除</el-button>
							</template>
						</el-table-column>
					</el-table>
				</el-form-item>
				<div class="filter-container" v-if="ruleForm.MaterialType==1">
					<el-form-item label="图片" prop="MaterialImgUrls">
						<div class="filter-item" style="vertical-align: top;">
							<upload-img
								@transPicture="transPictureFun"
								:AttachedImgUrls="ruleForm.MaterialImgUrls"
								:Type="2" 
							></upload-img>
							<div class="textColor" style="margin-top:20px">拖拽图片可以调整顺序，每张图片大小请控制在2M以内；图片最多上传9张</div>
						</div>
					</el-form-item>
				</div>
				<el-form-item label="视频" prop="MaterialVideoUrl" v-if="ruleForm.MaterialType==2">
					<div class="videoBox">
						<el-button
							type="text"
							style="font-size: 12px;"
							@click="deleteVideo"
							:disabled="!ruleForm.MaterialVideoUrl" 
						>删除视频</el-button>
						<el-upload
							v-loading="videoLoading" 
							element-loading-text="视频上传中..."
							element-loading-spinner="el-icon-loading"
							:action="videoApi"
							:show-file-list="false"
							:on-success="handleVideoSuccess"
							list-type="picture-card"
							:before-upload="beforeUploadVideo"
							:on-progress="uploadVideoProcess" 
							:disabled="type==1"
						>
							<video
								v-if="ruleForm.MaterialVideoUrl"
								:src="imgUrl+ruleForm.MaterialVideoUrl"
								class="avatar"
								controls="controls"
								style="width:148px;height:148px" 
							>您的浏览器不支持视频播放</video>
							<i v-else class="el-icon-plus avatar-uploader-icon"></i>
						</el-upload>
					</div>
					<p class="textColor">建议使用16：9比例视频；视频大小请控制在6M以内</p>
				</el-form-item>

				<el-form-item label="文本描述">
					<div style="width:600px;position: relative;">
						<el-input
							type="textarea"
							v-model="ruleForm.Description"
							style="width:600px"
							maxlength="500"
							placeholder="最多输入500字"
							@input="descInput"
							:rows="13"
							resize="none" 
							:disabled="type==1"
						></el-input>
						<span class="numberV" style="position: absolute;right: 10px;bottom: 0;">{{txtVal}}/500</span>
					</div>
				</el-form-item>
			</div>
		</el-form>
		<!-- 底部保存 -->
		<div class="bottom-save-btn">
			<el-button style="width:240px" @click="canselGoods">取消</el-button>
			<el-button
				type="primary"
				style="width:240px;margin:0 10px 0 20px"
				@click="submitForm('ruleForm')"
				:loading="loading" 
				v-if="type!=1"
			>保存</el-button>
		</div>
		<el-dialog title="选择商品" :visible.sync="selectProDialog" width="1400px" class="dialog">
			<select-produce api="activitycouponChoseproductlist" :selectedData="ruleForm.selectProduct" @getSelectList="getSelectList" :visible.sync="selectProDialog" v-if="selectProDialog"></select-produce>
		</el-dialog>
	</div>
</template>

<script>
import {
	addMaterial,
	findMaterialById,
	updateMaterial
} from '@/api/goods'
import config from '@/config/index'
import uploadImg from '@/components/uploadImgs/uploadPicture';
import selectProduce from './selectMultiPro.vue'
export default {
	components: {
		uploadImg,
		selectProduce
	},
	data () {
		return {
			ProductId: 0,
			txtVal: 0,
			imgUrl: config.IMG_BASE,
			imgApi: config.UPLOAD_IMG,
			videoApi: config.UPLOAD_VIDEO,
			loading: false,
			MeterialId: 0,
			ruleForm: {
				MaterialType: 1,
				MaterialImgUrls: [],
				MaterialVideoUrl: '',
				Description: '',
				selectProduct:[],
			},
			MeterialTypeList: [{
				Id: 1,
				Name: '图文'
			}, {
				Id: 2,
				Name: '视频'
			}],
			rules: {
				MaterialType: [{
					required: true,
					message: '请选择素材类型',
					trigger: 'change'
				}],
				MaterialImgUrls: [{
					type: 'array',
					required: true,
					message: '素材图片至少上传1张',
					trigger: 'change'
				}],
				MaterialVideoUrl: [{
					required: true,
					message: '请上传素材视频',
					trigger: 'change'
				}],
				selectProduct: [{
					type: 'array',
					required: true,
					message: '请选择关联商品',
					trigger: 'change'
				}],
			},
			selectProDialog:false,
			type:0,
			videoLoading:false
		}
	},
	created () {
		this.MeterialId = Number(this.$route.query.id) ? this.$route.query.id : 0
		this.type = Number(this.$route.query.type) ? this.$route.query.type : 0
		if (this.MeterialId) {
			this.getMeterialInfo();
			console.log("------",this.MeterialId)
		}
	},
	methods: {
		//切换素材类型
		ChangeMeterial () {
			this.ruleForm.Description = ''
			if (this.ruleForm.MaterialType == 1) {
				this.ruleForm.MaterialVideoUrl = ''
			}
			else {
				this.ruleForm.MaterialImgUrls = []
			}
		},
		submitForm (formName) {
			this.$refs[formName].validate(async (valid) => {
				if (valid) {
					this.loading = true;
					try {
						if (this.ruleForm.MaterialImgUrls.length > 9) {
							this.ruleForm.MaterialImgUrls = this.ruleForm.MaterialImgUrls.slice(0, 9)
						}
						let data = {
							goodsId: this.ruleForm.selectProduct[0].id,
							id: this.MeterialId,
							type: this.ruleForm.MaterialType,
							// imgList: this.ruleForm.MaterialImgUrls,
							// MaterialVideoUrl: this.ruleForm.MaterialVideoUrl,
							context: this.ruleForm.Description
						}
						if(this.ruleForm.MaterialType==1){
							var list = [];
							this.ruleForm.MaterialImgUrls.map(item=>{
								var obj = {
									img:item
								}
								list.push(obj);
								return item;
							})
							data.imgList = list;
						}else{
							let videolist = [];
							var obj = {
								img:this.ruleForm.MaterialVideoUrl
							}
							videolist.push(obj);
							data.imgList = videolist;
						}
						let result = null;
						if(this.type==2){
							result = await updateMaterial(data);
						}else{
							result = await addMaterial(data);
						}
						if (result.success) {
							this.$message({
								showClose: true,
								type: 'success',
								message: '保存成功!'
							});
							this.$router.push({
								path: '/material/materialList/materialList'
							});
						}else{
							this.$message({
								showClose: true,
								type: 'error',
								message: result.alertMsg
							});
						}
					} catch (error) {
						console.log(error)
					} finally {
						this.loading = false;
					}
				} else {
					console.log('error submit!!');
					return false;
				}
			});
		},
		// 取消操作
		canselGoods () {
			this.$router.push({
				path: '/material/materialList/materialList'
			});
		},
		descInput () {
			this.txtVal = this.ruleForm.Description.length;
		},
		uploadVideoProcess (event, file) {
			this.videoFlag = true;
			this.videoUploadPercent = file.percentage.toFixed(0);
		},
		// 上传视频
		beforeUploadVideo (file) {
			const isLt6M = file.size / 1024 / 1024 < 6;

			if (['video/mp4'].indexOf(file.type) == -1) {
				this.$message.error('请上传正确的视频格式');
				return false;
			}

			if (!isLt6M) {
				this.$message.error('主图视频请控制在6M以内');
				return false;
			}
			this.videoLoading = true;
		},
		handleVideoSuccess (res,) { //获取上传图片地址
			this.ruleForm.MaterialVideoUrl = res.data[0];
			this.videoLoading = false;
		},
		handleVideoError(){
			this.videoLoading = false;
		},
		deleteVideo () {
			this.ruleForm.MaterialVideoUrl = ''
		},
		// 批量上传的图片
		transPictureFun (datas) {
			this.ruleForm.MaterialImgUrls = datas;
			// if(this.ruleForm.MaterialImgUrls.length > 9){
			// 	this.ruleForm.MaterialImgUrls = this.ruleForm.MaterialImgUrls.slice(0,9)
			// }
		},
		async getMeterialInfo () {
			try {
				this.loading = true
				let data = {
					id: this.MeterialId
				}
				let result = await findMaterialById(data)
				if (result.success) {
					
					if(result.data.type==1){
						var list = [];
						result.data.imgList.map(item=>{
							list.push(item.img);
							return item;
						})
						this.ruleForm.MaterialImgUrls = list;
					}else{
						this.ruleForm.MaterialVideoUrl = result.data.imgList[0].img;
					}
					this.ruleForm.Description = result.data.context;
					this.ruleForm.MaterialType = result.data.type;
					if(result.data.goods){
						var obj = {
							goodsName: result.data.goods.goodsName,
							id: result.data.goods.id,
							isEnableShoppingCard: result.data.goods.isEnableShoppingCard,
							originalPrice: result.data.goods.originalPrice,
							salePrice: result.data.goods.salePrice,
							shelfStatus: result.data.goods.shelfStatus,
							thumbImgUrl: result.data.goods.thumbImgUrl,
							totalStock: result.data.goods.totalStock,
						}
						let list = [];
						list.push(obj);
						this.ruleForm.selectProduct = list;
						
					}
					
				}
			} catch (err) {
				console.log(err)
			} finally {
				this.loading = false
			}
		},
		handleSelectProDialog() {
			this.selectProDialog = true
		},
		//获取选择商品的列表
		getSelectList(data) {
			this.ruleForm.selectProduct = data;
			this.selectProDialog = false;
		},
		// 删除商品
		deleteSortPro() {
			this.ruleForm.selectProduct = [];
		},
	}
}
</script>

<style lang="less">
.CreatedMeterial {
	background: #fff;
	font-size: 15px;

	.baseInformtion {
		width: 100%;
		background: #fff;
		padding: 10px;
		margin-bottom: 12px;

		.infortion {
			margin-bottom: 20px;
			padding: 10px 0;
			border-bottom: 1px solid #ccc;
		}
	}

	.textColor {
		color: #999;
	}

	.bottom-save-btn {
		position: fixed;
		width: 100%;
		background: #fff;
		bottom: 0;
		text-align: center;
		padding: 20px 0;
		z-index: 100;
		box-shadow: 13px 1px 6px #999;
	}
}
</style>
